import Seo from "../components/Seo";
import * as React from "react";
import { Layout } from '../components/Layout';
import { Grid } from "@mui/material";
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
  return (
    <Layout
      seo={
        <Seo
          title="Kontakt | MgA. Jan Hlavatý"
          keywords={["akryl na plátně", "výtvarné umění", "MgA Jan Hlavatý"]}
          description=""
        />
      }
    >
      <Grid container justifyContent="center">
        <Grid item xs={10} sx={{}}>
          <ContactForm />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default ContactPage;
